<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Pagination from '@/components/Pagination/main.vue';
import Swal from 'sweetalert2';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import PickerDate from '@/components/DateCustoms/PickerDate/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';

import { keyMaster, listPaging, masterMethods, checkPermission, prizeMethods, employeeMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { validateField } from '@/utils/validate';
import { handleDetailReturnPrize } from './index';
import { InputCheckBox } from '@/components/Input';

export default {
    page: {
        title: '賞品戻り管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Pagination,
        Layout,
        LoadingIcon,
        Footer,
        Multiselect,
        PickerDate,
        DateNormal,
        InputCheckBox
    },
    data() {
        return {
            campaign_pic: null,
            listData: [],
            listDataMasterCampaignPic: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                campaign_name: '',
                reason: '',
                return_date: '',
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                storage_code: '',
                contact_information_number: '',
                shipping_company: '',
                province: '',
                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: '',
                event_prize: ''
            },
            has: {
                campaign_pic: '',
                prize: '',
                return_status: '',
                campaign: ''
            },
            perPageList: listPaging,
            checkShowFilter: false,
            configKeyword: {
                arrayTag: [],
                placeholder: '賞品タイトル、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo',
                isShowLable: true
            },
            isLoading: false,
            listReturnStatus: []
        };
    },
    watch: {
        campaign_pic: {
            deep: true,
            handler: function (value) {
                this.has.campaign_pic = value?.id ?? '';
            }
        },
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        }
    },
    mounted() {
        this.getListReturnStatus();
        this.getListMasterCampaignPic();
        this.getList();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...prizeMethods,
        ...employeeMethods,

        async getListMasterCampaignPic() {
            try {
                const dataRes = await this.listMaster('campaign/common-user');
                this.listDataMasterCampaignPic = dataRes['admin_and_instructor']; //1,3
            } catch (error) {
                console.log('error', error);
            }
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }

                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listPrizeReturn(query).then((data) => {
                handleDetailReturnPrize(data);
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        deletePrizeRe(data) {
            Swal.fire({
                title: '賞品戻りを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.value) {
                    this.deletePrizeReturn(data).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '賞品戻りが削除されました。');
                            this.getList();
                        }
                    });
                }
            });
        },
        search() {
            let data = {
                form: this.form,
                filter: this.filter
            };
            localStorage.setItem('employee', JSON.stringify(data));
            this.page = 1;
            this.getList();
        },
        clear() {
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名、賞品タイトル、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo',
                isShowLable: true
            };
            this.form = {
                campaign_name: '',
                reason: '',
                return_date: '',
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                storage_code: '',
                contact_information_number: '',
                shipping_company: '',
                province: '',
                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: '',
                prize: ''
            };
            this.has = {
                campaign_pic: '',
                prize: '',
                return_status: '',
                campaign: ''
            };
            this.campaign_pic = null;
            this.getList();
        },
        saveItem(item) {
            if (!checkPermission('return_prize.edit')) return;
            if (!item.reason || !item.return_status || !item.return_date) {
                return;
            }
            item = {
                ...item,
                return_status: {
                    id: item?.return_status?.id ?? null
                }
            };
            this.updatePrizeReturn(item).then((data) => {
                if (data.code !== 200) {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }
            });
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = [];
            } else {
                this.configKeyword.placeholder = '賞品タイトル、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo';
                this.configKeyword.arrayTag = [];
            }
        },

        async getListReturnStatus() {
            this.isLoading = true;

            try {
                let data = await this.listMaster(keyMaster['prize.corresponding_status']);
                this.listReturnStatus = data;
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },

        hasReturnStatus(param) {
            return param.return_status?.value === '確認中' || param.return_status?.value === '保管' ? false : true;
        },

        handleRemoveReturnStatus(obj) {
            let check = true;
            obj.reconfirmation_schedule = null;
            check = validateField(obj.configReturnStatus, check);
            if (!check) return;
            this.saveItem(obj);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名<span class="text-danger">*</span></label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.campaign_name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">賞品タイトル</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.event_prize" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">戻り理由</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.reason" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">案件担当</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-campaign_pic-default`"
                                                    :value.sync="campaign_pic"
                                                    :options="listDataMasterCampaignPic"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false,
                                                        searchable: true
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">戻り日</label>
                                            <div class="col-sm-9">
                                                <DateNormal
                                                    :year.sync="form.return_date_y"
                                                    :month.sync="form.return_date_m"
                                                    :day.sync="form.return_date_d"
                                                    :full_date.sync="form.return_date"
                                                    :config="{
                                                        errorText: '',
                                                        valueSync: '',
                                                        error: false
                                                    }"
                                                >
                                                </DateNormal>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">保管棚番</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.storage_code" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">運送会社</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.shipping_company" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">都道府県</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.province" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">事務局終了日</label>
                                            <div class="col-sm-9">
                                                <DateNormal
                                                    :year.sync="form.secretariat_end_date_y"
                                                    :month.sync="form.secretariat_end_date_m"
                                                    :day.sync="form.secretariat_end_date_d"
                                                    :full_date.sync="form.secretariat_end_date"
                                                    :config="{
                                                        errorText: '',
                                                        valueSync: '',
                                                        error: false
                                                    }"
                                                >
                                                </DateNormal>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">問い合わせNo</label>
                                            <div class="col-sm-9">
                                                <input
                                                    id="validationCustom05"
                                                    v-model="form.contact_information_number"
                                                    type="text"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>

                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">賞品戻り検索結果 （{{ total }})</h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered table-box">
                                <thead>
                                    <tr>
                                        <th>終了FLG</th>
                                        <th>No.</th>
                                        <th>戻り日 <span class="text-danger">*</span></th>
                                        <th>キャンペーン名</th>
                                        <th>賞品タイトル</th>
                                        <th>都道府県</th>
                                        <th>返送運賃（倉庫着払い）</th>
                                        <th>戻り対応ステータス <span class="text-danger">*</span></th>
                                        <th>再確認日</th>
                                        <th>運送会社</th>
                                        <th>問合せ№</th>
                                        <th>戻り理由 <span class="text-danger">*</span></th>
                                        <th>パルディアより対応指示項目</th>
                                        <th>日本物流開発様対応</th>
                                        <th>追跡番号</th>
                                        <th>保管棚番</th>
                                        <th>備考</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template>
                                        <tr v-for="(item, k) in listData" :key="'ac' + k">
                                            <!-- 終了FLG -->
                                            <td>
                                                <div>
                                                    <InputCheckBox
                                                        class="custom-switch"
                                                        :model.sync="item.end_flag"
                                                        :id="`__BVID__742` + k"
                                                        :value="true"
                                                        :label="``"
                                                        @change="saveItem(item)"
                                                    />
                                                </div>
                                            </td>
                                            <!-- No. -->
                                            <td>
                                                {{ k + 1 }}
                                            </td>
                                            <!-- 戻り日 * -->
                                            <td style="width: 123px">
                                                <div>
                                                    <PickerDate
                                                        style="min-width: 130px"
                                                        :id="`return_date${k}`"
                                                        :model.sync="item.return_date"
                                                        :config.sync="item.configFieldReturnDate"
                                                        @change="saveItem(item)"
                                                    />
                                                </div>
                                                <div v-if="!item.return_date" class="mt-1">
                                                    <span class="text-danger">{{
                                                        $t('validateField.requiredSelect', {
                                                            field: '戻り日'
                                                        })
                                                    }}</span>
                                                </div>
                                            </td>
                                            <!-- キャンペーン名 -->
                                            <td>
                                                <div class="row-data-small" v-b-tooltip.hover :title="item.event_prize?.campaign?.name ?? ''">
                                                    {{ item?.event_prize?.campaign?.name ?? '' }}
                                                </div>
                                            </td>
                                            <!-- 賞品タイトル -->
                                            <td>
                                                <div class="row-data-small" v-b-tooltip.hover :title="item.event_prize?.title ?? ''">
                                                    {{ item?.event_prize?.title ?? '' }}
                                                </div>
                                            </td>
                                            <!-- 都道府県 -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.province"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 返送運賃（倉庫着払い） -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.shipping_return_fee"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 戻り対応ステータス -->
                                            <td>
                                                <Multiselect
                                                    :id="`select-return_status-${k}`"
                                                    :value.sync="item.return_status"
                                                    :options="listReturnStatus"
                                                    :config="item.configReturnStatus"
                                                    @select="handleRemoveReturnStatus(item)"
                                                    @remove="handleRemoveReturnStatus(item)"
                                                />
                                            </td>
                                            <!-- 再確認日 -->
                                            <td>
                                                <div>
                                                    <PickerDate
                                                        style="min-width: 130px"
                                                        :id="`scheduled_arrived_at${k}`"
                                                        :model.sync="item.reconfirmation_schedule"
                                                        :config.sync="item.configFieldReconfirmation"
                                                        :disabled="hasReturnStatus(item)"
                                                        @change="saveItem(item)"
                                                    />
                                                </div>
                                            </td>
                                            <!-- 運送会社 -->
                                            <td>
                                                <input
                                                    type="text"
                                                    v-model="item.shipping_company"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                    @change="saveItem(item)"
                                                />
                                            </td>
                                            <!-- 問合せ№ -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    style="width: 140px"
                                                    v-model="item.contact_information_number"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 戻り理由 * -->
                                            <td class="input-group-sm">
                                                <div>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        style="min-width: 140px"
                                                        v-model="item.reason"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{ 'is-invalid': !item.reason }"
                                                    />
                                                </div>
                                                <div v-if="!item.reason" class="mt-1">
                                                    <span class="text-danger">{{
                                                        $t('validateField.required', {
                                                            field: '戻り理由'
                                                        })
                                                    }}</span>
                                                </div>
                                            </td>

                                            <!-- パルディアより対応指示項目 -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.instruction"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 日本物流開発様対応	-->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.compatiable_with_jp_logistic_development"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 追跡番号 -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.tracking_number"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 保管棚番 -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.storage_code"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <!-- 備考 -->
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.note"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td align="center">
                                                <button
                                                    v-if="checkPermission('return_prize.delete')"
                                                    class="btn btn-sm btn-outline-danger"
                                                    type="button"
                                                    @click="deletePrizeRe(item)"
                                                    style="border-radius: 50%"
                                                >
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss">
th,
td {
    white-space: nowrap;
}
th:last-child,
td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
    z-index: 999;
}
.mx-input {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    min-width: 123px;
}
</style>
